import logo from './logo.svg';
import './App.css';
import React from 'react';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { version: null };
    }

    componentDidMount() {
        fetch('https://api.artifactor.ai/version')
            .then(response => response.json())
            .then(data => this.setState({ version: data.version }));

        // fetch('https://api.npms.io/v2/search?q=react')
        //     .then(response => response.json())
        //     .then(data => this.setState({ version: data.total }));
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p>
                        Api Version: {this.state.version}
                    </p>
                </header>
            </div>
        );
    }
}

export default App;